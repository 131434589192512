import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { Loading } from "../Components/Loading";
import "../App.css";
import { ImageUpload } from "../Components/ImageUpload";
import LightboxImage from "../Components/Lightbox";
import { CheckListItem } from "../Components/Checklist";

@inject("AppStore")
@observer
class ServiceDetail extends React.Component {
  store = this.props.AppStore;

  state = {
    engineLightbox: false,
    engineLightboxIndex: 0,
    electricalLightbox: false,
    electricalLightboxIndex: 0,
    furnitureLightbox: false,
    furnitureLightboxIndex: 0,
  };

  async componentDidMount() {
    await this.store.loadConfig();

    const { user } = this.store;
    if (!user) window.location.href = "/";

    const { id } = this.props.match.params;

    await this.store.getServiceDetail(id);
    this.store.service.number = id;
  }

  openEngineLightbox(index) {
    this.setState({ engineLightbox: true, engineLightboxIndex: index });
  }

  openElectricalLightbox(index) {
    this.setState({ electricalLightbox: true, electricalLightboxIndex: index });
  }

  openFurnitureLightbox(index) {
    this.setState({ furnitureLightbox: true, furnitureLightboxIndex: index });
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    const data = this.store.service_data;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.page.lang}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {this.store.configuration && this.store.configuration.logo && (
                <img
                  alt="logo"
                  src={appConfig.locale.api + this.store.configuration.logo.url}
                  className="logo"
                />
              )}
              <span>
                {this.store.location && this.store.location.displayname}
              </span>
            </a>
          </div>
          {this.store.user ? (
            <div className="header-user">
              <span>{this.store.user.user.fullname} -&nbsp;</span>
              <a onClick={this.store.logout}>LOGOUT</a>
            </div>
          ) : (
            <div className="header-user">
              <a href={`/account`}>Account</a>
            </div>
          )}
        </div>
        <div style={{ padding: "0 25px" }}>
          <div>
            <h2>SERVIS KONTROL FORMU</h2>
          </div>
          <div>
            <h3>Müşteri Bilgileri</h3>
          </div>
          <div className="form-build">
            <div className="form-item">
              <label>Konfigurasyon ID</label>
              <input
                disabled
                type="text"
                name="config_id"
                value={`${data.order_number}`}
              />
            </div>
            <div className="form-item">
              <label>Ad Soyad</label>
              <input
                disabled
                type="text"
                name="fullname"
                value={`${data.firstname} ${data.lastname}`}
              />
            </div>
            <div className="form-item">
              <label>Telefon</label>
              <input disabled type="text" name="phone" value={data.phone} />
            </div>
            <div className="form-item">
              <label>Email</label>
              <input disabled type="text" name="email" value={data.email} />
            </div>
          </div>
          <div>
            <h3>Tekne Bilgileri</h3>
          </div>
          <div className="form-build">
            <div className="form-item">
              <label>Model</label>
              <input
                disabled
                type="text"
                name="model"
                value={data.model.name}
              />
            </div>
            <div className="form-item">
              <label>Slot No</label>
              <input
                disabled
                type="text"
                name="slot_number"
                value={data.slot_number}
              />
            </div>
            <div className="form-item">
              <label>Üretim Yılı</label>
              <input
                disabled
                type="text"
                name="built_year"
                value={data.built_year}
              />
            </div>

            <div className="form-item">
              <label>Model Yılı</label>
              <input
                disabled
                type="text"
                name="model_year"
                value={data.model_year}
              />
            </div>
            <div className="form-item">
              <label>Bayrak</label>
              <input disabled type="text" name="flag" value={data.flag} />
            </div>
            <div className="form-item">
              <label>Notlar</label>
              <pre
                className="textarea"
                dangerouslySetInnerHTML={{ __html: data.factory_notes }}
              ></pre>
            </div>
          </div>

          <div>
            <h3>Motor Kontrolleri</h3>
          </div>
          <div>
            <table className="checklist" style={{ width: "100%" }}>
              <tbody>
                <CheckListItem
                  object={this.store.service}
                  field="motor_kontrolleri"
                  text="Motor Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="motor_kayis_kontrolleri"
                  text="Motor Kayış Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="motor_th_baglanto_kontrolleri"
                  text="Motor T.H Bağlantı Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="mercury_jpo"
                  text="Mercury JPO"
                />

                <CheckListItem
                  object={this.store.service}
                  field="motor_baglanti_civatalari"
                  text="Motor Bağlantı Civataları"
                />

                <CheckListItem
                  object={this.store.service}
                  field="batarya_and_aku_sarj_kontrolleri_sahil_besleme"
                  text="Batarya & Akü Şarj / Sahil Besleme"
                />
              </tbody>
            </table>
          </div>

          <div className="attachments">
            <h3>Ekler</h3>

            {this.store.service_data.service && (
              <React.Fragment>
                {this.state.engineLightbox && (
                  <LightboxImage
                    images={this.store.service_data.service.motor_kontrolleri_galeri.map(
                      (i) => `${appConfig.api}${i.url}`
                    )}
                    photoIndex={this.state.engineLightboxIndex}
                    onCloseRequest={() =>
                      this.setState({ engineLightbox: false })
                    }
                  />
                )}

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                  }}
                >
                  {this.store.service_data.service.motor_kontrolleri_galeri.map(
                    (item, i) => (
                      <div key={i}>
                        <div
                          onClick={() => this.openEngineLightbox(i)}
                          style={{ display: "block", padding: 10 }}
                        >
                          <img
                            src={`${appConfig.api}${item.url}`}
                            alt=""
                            width="100%"
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            )}
          </div>

          <div>
            <ImageUpload
              onChange={(v) => (this.store.service_gallery.engine = v)}
            />
          </div>

          <hr />

          <div>
            <h3>Elektrik Sistemleri</h3>
          </div>
          <div>
            <table className="checklist" style={{ width: "100%" }}>
              <tbody>
                <CheckListItem
                  object={this.store.service}
                  field="silecek_kontrolu"
                  text="Silecek Kontrolü"
                />

                <CheckListItem
                  object={this.store.service}
                  field="aydinlatma_kontrolu"
                  text="Aydınlatma Kontrolü"
                />

                <CheckListItem
                  object={this.store.service}
                  field="irgat"
                  text="Irgat"
                />

                <CheckListItem
                  object={this.store.service}
                  field="bas_pervane"
                  text="Baş Pervane"
                />

                <CheckListItem
                  object={this.store.service}
                  field="teras_acilma_kapama"
                  text="Teras Açılma Kapama"
                />

                <CheckListItem
                  object={this.store.service}
                  field="teras_kilit"
                  text="Teras Kilit"
                />

                <CheckListItem
                  object={this.store.service}
                  field="sahil_baglanti_hatti"
                  text="Sahil Bağlantı Hattı"
                />

                <CheckListItem
                  object={this.store.service}
                  field="redresor"
                  text="Redresör"
                />

                <CheckListItem
                  object={this.store.service}
                  field="buzdolabi"
                  text="Buzdolabı"
                />

                <CheckListItem
                  object={this.store.service}
                  field="simrad_ekran"
                  text="Simrad Ekran"
                />

                <CheckListItem
                  object={this.store.service}
                  field="flap_sistemi"
                  text="Flap Sistemi"
                />

                <CheckListItem
                  object={this.store.service}
                  field="ses_sistemi"
                  text="Ses Sistemi"
                />

                <CheckListItem
                  object={this.store.service}
                  field="radar_kontrolleri"
                  text="Radar Kontroller"
                />

                <CheckListItem
                  object={this.store.service}
                  field="simrad_birim_ayarlari"
                  text="Simrad Birim Ayarları"
                />
              </tbody>
            </table>
          </div>

          <div className="attachments">
            <h3>Ekler</h3>

            {this.store.service_data.service && (
              <React.Fragment>
                {this.state.electricalLightbox && (
                  <LightboxImage
                    images={this.store.service_data.service.elektrik_sistemleri_galeri.map(
                      (i) => `${appConfig.api}${i.url}`
                    )}
                    photoIndex={this.state.electricalLightboxIndex}
                    onCloseRequest={() =>
                      this.setState({ electricalLightbox: false })
                    }
                  />
                )}

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                  }}
                >
                  {this.store.service_data.service.elektrik_sistemleri_galeri.map(
                    (item, i) => (
                      <div key={i}>
                        <div
                          onClick={() => this.openElectricalLightbox(i)}
                          style={{ display: "block", padding: 10 }}
                        >
                          <img
                            src={`${appConfig.api}${item.url}`}
                            alt=""
                            width="100%"
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            )}
          </div>

          <div>
            <ImageUpload
              onChange={(v) => (this.store.service_gallery.electrical = v)}
            />
          </div>

          <hr />
          <div>
            <h3>Mobilyalar</h3>
          </div>
          <div>
            <table className="checklist" style={{ width: "100%" }}>
              <tbody>
                <CheckListItem
                  object={this.store.service}
                  field="tum_mobilyalarin_vida_baglantilari_vb_kontrolleri"
                  text="Vida ve Bağlantılar"
                />

                <CheckListItem
                  object={this.store.service}
                  field="ayna"
                  text="Ayna"
                />

                <CheckListItem
                  object={this.store.service}
                  field="kapi"
                  text="Kapı"
                />

                <CheckListItem
                  object={this.store.service}
                  field="kapak"
                  text="Kapak"
                />

                <CheckListItem
                  object={this.store.service}
                  field="mutfak_gideri"
                  text="Mutfak Giderleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="wc_giderleri"
                  text="WC Giderleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="pis_su_giderleri"
                  text="Pis Su Giderleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="tum_borularin_akinti_kontrolleri_ve_kelepceleri"
                  text="Boruların Akıntı ve Kelepçe Kont."
                />

                <CheckListItem
                  object={this.store.service}
                  field="kapi_menteselerinin_kontrolleri"
                  text="Kapı Menteşelerinin Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="teras_mentese_kontrolleri"
                  text="Teras Menteşe Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="on_kapi_su_girme_kontrolu"
                  text="Ön Kapı Su Girme Kontorolü"
                />

                <CheckListItem
                  object={this.store.service}
                  field="kamera_hachlerinin_su_sizdirma_kontrolleri"
                  text="Kamera Hachlerinin Su Sızdırması Kont."
                />

                <CheckListItem
                  object={this.store.service}
                  field="cam_kontrolleri"
                  text="Cam Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="sika_kontrolu"
                  text="Sika Kontrolü"
                />

                <CheckListItem
                  object={this.store.service}
                  field="doseme_kontrolleri"
                  text="Döşeme Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="pvc_teak"
                  text="PVC Teak"
                />

                <CheckListItem
                  object={this.store.service}
                  field="su_tanklarinin_kontrolleri"
                  text="Su Tanklarının Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="govde_genel_kontrolu"
                  text="Gövde Genel Kontrolleri"
                />

                <CheckListItem
                  object={this.store.service}
                  field="yakit_ve_yakit_tanki_kontrolu"
                  text="Yakıt ve Yakıt Tankı Kontrolü"
                />

                <CheckListItem
                  object={this.store.service}
                  field="gelcoat_kontrolu"
                  text="Gelcoat Kontrolü"
                />

                <CheckListItem
                  object={this.store.service}
                  field="su_hatti"
                  text="Su Hattı"
                />
              </tbody>
            </table>
          </div>
          <div className="attachments">
            <h3>Ekler</h3>
            {this.store.service_data.service && (
              <React.Fragment>
                {this.state.furnitureLightbox && (
                  <LightboxImage
                    images={this.store.service_data.service.mobilyalar_galeri.map(
                      (i) => `${appConfig.api}${i.url}`
                    )}
                    photoIndex={this.state.furnitureLightboxIndex}
                    onCloseRequest={() =>
                      this.setState({ furnitureLightbox: false })
                    }
                  />
                )}

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                  }}
                >
                  {this.store.service_data.service.mobilyalar_galeri.map(
                    (item, i) => (
                      <div key={i}>
                        <div
                          onClick={() => this.openFurnitureLightbox(i)}
                          style={{ display: "block", padding: 10 }}
                        >
                          <img
                            src={`${appConfig.api}${item.url}`}
                            alt=""
                            width="100%"
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            )}
          </div>

          <div>
            <ImageUpload
              onChange={(v) => (this.store.service_gallery.furniture = v)}
            />
          </div>

          <div style={{ margin: "30px 0" }}>
            <button
              onClick={(e) => this.store.onServiceSave()}
              style={{
                cursor: "pointer",
                appearance: "none",
                border: "none",
                background: "green",
                color: "#fff",
                width: "100%",
                padding: "10px 160px",
                display: "block",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceDetail;
