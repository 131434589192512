export default {
  hello: { en: "Hello", tr: "Merhaba" },
  "TECHNICAL SPECIFICATIONS": {
    en: "TECHNICAL SPECIFICATIONS",
    tr: "TEKNIK DETAYLAR",
  },
  SUMMARY: {
    en: "SUMMARY",
    tr: "ÖZET",
  },
  BUILDS: {
    en: "BUILDS",
    tr: "Konfigurasyonlar",
  },
  SERVICE: {
    en: "SERVICE",
    tr: "SERViS",
  },
  SELECT_INFO: {
    en: "*Manufacturer’s Suggested Retail Price (MSRP), excludes options, taxes, title and registration processing.",
    tr: "",
  },
  Length: {
    en: "Length",
    tr: "Boy",
  },
  Beam: {
    en: "Beam",
    tr: "Genişlik",
  },
  "Beam with open Side Teracces": {
    en: "Beam with open Side Teracces",
    tr: "Açık teras genişliği",
  },
  Draft: {
    en: "Draft",
    tr: "Draft",
  },
  Displacement: {
    en: "Displacement",
    tr: "Displacement",
  },
  Passengers: {
    en: "Passengers",
    tr: "Kapasite",
  },
  Berths: {
    en: "Berths",
    tr: "Berth",
  },
  "Fuel Cap": {
    en: "Fuel Cap.",
    tr: "Yakıt kap.",
  },
  Range: {
    en: "Range",
    tr: "Menzil",
  },
  "Fresh Water Cap": {
    en: "Fresh Water Cap",
    tr: "Su kapasitesi",
  },
  Classification: {
    en: "Classification",
    tr: "Sınıf",
  },
  "ENGINE TYPES": {
    en: "ENGINE TYPES",
    tr: "MOTOR SEÇENEKLERİ",
  },
  "Engine Type": {
    en: "Engine Type",
    tr: "Motor Tipi",
  },
  Propulsion: {
    en: "Propulsion",
    tr: "İtme",
  },
  "Fuel Type": {
    en: "Fuel Type",
    tr: "Yakıt Tipi",
  },
  Engines: {
    en: "Engines",
    tr: "Motorlar",
  },
  Speed: {
    en: "Speed",
    tr: "Hız",
  },
  "EXTRA INCLUDED": {
    en: "EXTRA INCLUDED",
    tr: "EKSTRALAR",
  },
  "STANDARD FEATURES": {
    en: "STANDARD FEATURES",
    tr: "STANDART ÖZELLİKLER",
  },
  "STANDARD SPECIFICATIONS": {
    en: "STANDARD SPECIFICATIONS",
    tr: "STANDARD SPESİFİKASYONLAR",
  },
  STANDARD_INFO: {
    en: `Specifications are based on available information at the time. Actual specifications may vary. [name] reserves the right to change product line specifications, equipment, color scheme and pricing, or to discontinue models at any time without notice or obligation.`,
    tr: `Spesifikasyonlar, o sırada mevcut olan bilgilere dayanmaktadır. Özellikler değişebilir. [name] herhangi bir zamanda bildirimde bulunmaksızın veya herhangi bir yükümlülük altına girmeksizin ürün hattı özelliklerini, ekipmanı, renk şemasını ve fiyatlandırmayı değiştirme veya modelleri durdurma hakkını saklı tutar.`,
  },
  "PURCHASE PRICE": {
    en: "PURCHASE PRICE",
    tr: "SATIŞ FİYATI",
  },
  PRICE: {
    en: "OPTIONS PRICE",
    tr: "OPSİYONLARIN TOPLAM FİYATI",
  },
  Power: {
    en: "Power",
    tr: "Motor Gücü",
  },
  SELECT_YOUR: {
    en: "[name] Configurator",
    tr: "[name] Konfigurator",
  },
  "Transportation Fee": {
    en: "TRANSPORTATION / HANDLING / DELIVERY COSTS",
    tr: "NAKLİYE / TAŞIMA / TESLİMAT MALİYETLERİ",
  },

  "Total Price": {
    en: "Total Price",
    tr: "Toplam Fiyat",
  },

  TRANSPORTATION_INFO: {
    en: `
*This service covers all the key aspects of transportation from the Polish shipyard to Istanbul. The process begins with transportation preparation and loading, including protective measures such as cradles and shrink wrap. Crane rental and labor costs are also taken into account during the transport. Additionally, hidden road fees, special road permits between Poland and Turkey, and export expenses are included. Transportation insurance and customs procedures are part of the process as well.
<br />
<br />
Upon arrival in Istanbul, the service covers 3 days of marina accommodation, machinery start-up costs, and launch lift fees. The opening costs of electronic systems like Simrad and Mastervolt are also included. Finally, the cost of fuel and test runs for the sea trial is factored in, providing a comprehensive service package.
`,
    tr: `
*Bu hizmet, Polonya tersanesinden İstanbul’a kadar olan nakliye sürecindeki tüm önemli detayları kapsamaktadır. Nakliye hazırlığı ve yükleme işlemleri, cradle ve shrink wrap gibi koruyucu önlemlerle başlar. Nakliye sırasında vinç kiralama ve işçilik maliyetleri göz önünde bulundurulur. Ayrıca, görünmeyen yol harçları, Polonya-Türkiye arasında özel yol izinleri ve ihracat masrafları da hesaba katılmaktadır. Nakliyat sigortası ve gümrük işlemleri de sürecin bir parçasıdır.
<br />
<br />
İstanbul’a varışta, 3 günlük marina konaklama masrafları, makine start-up maliyetleri ve denize indirme lift ücretleri karşılanmaktadır. Simrad ve Mastervolt gibi elektronik sistemlerin açılış masrafları da bu kapsama dahil edilir. Son olarak, deniz testi için gerekli yakıt ve test sürüşü maliyetleri de düşünülerek kapsamlı bir hizmet sunulmaktadır.
`
  },

  From: {
    en: "from",
    tr: "başlangıç fiyatı",
  },
  "CONFIGURE YOUR": {
    en: "CONFIGURE YOUR [name]",
    tr: "[name] OLUŞTURUN",
  },
  "ORDER SUMMARY": {
    en: "ORDER SUMMARY",
    tr: "SİPARİŞ ÖZETİ",
  },
  "SEND PDF": {
    en: "SEND PDF",
    tr: "PDF GÖNDER",
  },
  "ENTER YOUR DETAILS": {
    en: "ENTER YOUR DETAILS",
    tr: "KİŞİSEL BİLGİLER",
  },
  SUMMARY_INFO: {
    en: "*Purchase Price including selected options & delivery but excludes taxes, title and registration processing.",
    tr: "*Satış fiyatı seçilenleri ve transferi içerir. Vergiler ve kayıt ücretleri dahil değildir.",
  },

  next: { en: "NEXT", tr: "İLERİ" },
  prev: { en: "BACK", tr: "GERİ" },
  button_pdf: { en: "SEND ME PDF", tr: "PDF GÖNDER" },
  button_buy: { en: "BUY NOW", tr: "HEMEN SATIN AL" },
  footer_purchase_price: { en: "Options Price", tr: "Opsiyonların Toplam Fiyatı" },
  footer_estimate_payment: { en: "Estimate Payment", tr: "Tahmini Ödeme" },
  footer_estimate_delivery: {
    en: "Estimate Delivery<br />6-8 Weeks",
    tr: "Tahmini Teslim Süresi<br />6-8 Hafta",
  },
  summary_title: {
    en: "Your [model] is ready to go!",
    tr: "[model] hazır!",
  },
  summary_standards: {
    en: "STANDARDS",
    tr: "STANDART DONANIM",
  },
  summary_upgrade: {
    en: "UPGRADES & OPTIONS",
    tr: "EKLENTİLER VE SEÇENEKLER",
  },

  form_details: {
    en: "My Details",
    tr: "Kişisel Bilgilerim",
  },
  form_first_name: {
    en: "First Name",
    tr: "İsim",
  },
  form_last_name: {
    en: "Last Name",
    tr: "Soyisim",
  },
  form_email: {
    en: "Email Address",
    tr: "E-Posta Adresi",
  },
  form_phone: {
    en: "Phone Number",
    tr: "Telefon Numarası",
  },
  form_boating: {
    en: "Where are you boating?",
    tr: "Seyir yaptığınız yer?",
  },
};
