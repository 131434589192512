import React from "react";
import ImageUploading from "react-images-uploading";

export const ImageUpload = (props) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 10;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    props.onChange(imageList.map((x) => x.file));
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div>
              <button
                className="button-16 "
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                Yeni Ekle
              </button>
              &nbsp;
              <button className="button-16" onClick={onImageRemoveAll}>
                Eklenenleri Sil
              </button>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image["data_url"]} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    <button
                      className="button-16"
                      onClick={() => onImageUpdate(index)}
                    >
                      Değiştir
                    </button>
                    <button
                      className="button-16"
                      onClick={() => onImageRemove(index)}
                    >
                      Sil
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
