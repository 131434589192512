import React from "react";
import { inject, observer } from "mobx-react";
import { convertCurrency, convertCurrencySummaryLocation } from "../helpers";
import { Translation } from "../Translation";
import { EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import RichTextEditor from "./RichTextEditor";

@inject("AppStore")
@observer
class BrokerStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
    this.onChange = (editorState) => this.setState({ editorState });
  }

  render() {
    const { store } = this.props;
    const { brokerageForm } = store;

    return (
      <div className="step-payment-content" id="pdf-step">
        <div className="left">
          <h2>SALES AGREEMENT</h2>

          <div className="brokerage-section">
            <h3>Buyer Information</h3>

            <div className="form">
              <div className="form-item">
                <label>Buyer's Firstname</label>
                <input
                  required
                  type="text"
                  name="buyer_firstname"
                  value={store.brokerageForm.buyer.firstname}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.firstname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Buyer's Lastname</label>
                <input
                  required
                  type="text"
                  name="buyer_lastname"
                  value={store.brokerageForm.buyer.lastname}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.lastname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>E-mail Address</label>
                <input
                  required
                  type="email"
                  name="email"
                  value={store.brokerageForm.buyer.email}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.email = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Cell Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={store.brokerageForm.buyer.phone}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.phone = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Business Phone</label>
                <input
                  type="text"
                  name="business_phone"
                  value={store.brokerageForm.buyer.business_phone}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.business_phone = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Buyer's Address</label>
                <input
                  type="text"
                  name="address"
                  value={store.brokerageForm.buyer.address}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.address = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Buyer's TC / Passport Number</label>
                <input
                  type="text"
                  name="identity"
                  value={store.brokerageForm.buyer.identity}
                  onChange={(e) =>
                    (store.brokerageForm.buyer.identity = e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="brokerage-section">
            <h3>Boat Information</h3>
            <div className="form">
              <div className="form-item">
                <label>Slot Number</label>
                <input
                  required
                  type="text"
                  name="slot_number"
                  value={store.brokerageForm.boat.slot_number}
                  onChange={(e) =>
                    (store.brokerageForm.boat.slot_number = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Built Year</label>
                <input
                  required
                  type="text"
                  name="built_year"
                  value={store.brokerageForm.boat.built_year}
                  onChange={(e) =>
                    (store.brokerageForm.boat.built_year = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Model Year</label>
                <input
                  required
                  type="text"
                  name="model_year"
                  value={store.brokerageForm.boat.model_year}
                  onChange={(e) =>
                    (store.brokerageForm.boat.model_year = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Flag</label>
                <input
                  required
                  type="text"
                  name="flag"
                  value={store.brokerageForm.boat.flag}
                  onChange={(e) =>
                    (store.brokerageForm.boat.flag = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Order Date</label>
                <input
                  required
                  type="text"
                  name="order_date"
                  value={store.brokerageForm.boat.order_date}
                  onChange={(e) =>
                    (store.brokerageForm.boat.order_date = e.target.value)
                  }
                />
              </div>

              <div className="form-item" style={{ gridColumn: "1/3" }}>
                <label>Extras</label>
                <RichTextEditor
                  value={store.brokerageForm.boat.extras}
                  onChange={(e) => (store.brokerageForm.boat.extras = e)}
                />
              </div>

              <div
                className="form-item"
                style={{ display: "none", gridColumn: "1/3" }}
              >
                <label>Extras</label>
                <textarea
                  style={{
                    width: "100%",
                    height: "200px",
                    resize: "none",
                    padding: "0.8rem",
                    borderRadius: "4px",
                    borderColor: "#ccc",
                  }}
                  onChange={(e) => this.setState({ extras: e.target.value })}
                  onBlur={(e) =>
                    (store.brokerageForm.boat.extras = this.state.extras)
                  }
                  value={this.state.extras}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="brokerage-section" style={{ display: "none" }}>
            <h3>Pricing</h3>

            <div className="brokerage-pricing">
              <div>How many = 4</div>
              <div>
                {brokerageForm.payments.map((payment, i) => (
                  <div></div>
                ))}
              </div>
            </div>
          </div>

          <div className="brokerage-section">
            <h3>Summary</h3>

            <div>
              <table className="brokerage-summary">
                <tbody>
                  <tr>
                    <td>Options Price</td>
                    <td align="right" width="20%">
                      {convertCurrency(
                        brokerageForm.price.base_price,
                        store.location
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Additional Factory Options (Exhibit A)</td>
                    <td align="right">
                      {convertCurrency(
                        brokerageForm.price.additional_price,
                        store.location
                      )}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>Rigging and Preparations for Delivery, Incl. Fuel</td>
                    <td align="right">
                      {store.broker_active_field === "rigging" ? (
                        <input
                          autoFocus
                          className="brokerage_input"
                          type="number"
                          value={brokerageForm.price.rigging}
                          onChange={(e) => {
                            if (e.target.value)
                              brokerageForm.price.rigging = +e.target.value;
                            else brokerageForm.price.rigging = undefined;
                          }}
                        />
                      ) : (
                        <span
                          onClick={() => {
                            store.unlock_field("rigging");
                            if (brokerageForm.price.rigging === 0)
                              brokerageForm.price.rigging = null;
                          }}
                        >
                          {convertCurrency(
                            brokerageForm.price.rigging,
                            store.location
                          )}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Extra Costs – Import Duties</td>
                    <td align="right">
                      {store.broker_active_field === "extra_cost" ? (
                        <input
                          autoFocus
                          className="brokerage_input"
                          type="number"
                          value={brokerageForm.price.extra_cost}
                          onChange={(e) => {
                            if (e.target.value) {
                              brokerageForm.price.extra_cost = +e.target.value;
                            } else brokerageForm.price.extra_cost = undefined;
                          }}
                        />
                      ) : (
                        <span
                          onClick={() => {
                            store.unlock_field("extra_cost");
                            if (brokerageForm.price.extra_cost === 0)
                              brokerageForm.price.extra_cost = null;
                          }}
                        >
                          {convertCurrency(
                            brokerageForm.price.extra_cost,
                            store.location
                          )}
                        </span>
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td>Transport Shipping to FOB Location</td>
                    <td align="right">
                      {store.broker_active_field === "transport" ? (
                        <input
                          autoFocus
                          className="brokerage_input"
                          type="number"
                          value={brokerageForm.price.transport}
                          onChange={(e) => {
                            if (e.target.value)
                              brokerageForm.price.transport = +e.target.value;
                            else brokerageForm.price.transport = undefined;
                          }}
                        />
                      ) : (
                        <span
                          onClick={() => {
                            store.unlock_field("transport");
                            if (brokerageForm.price.transport === 0)
                              brokerageForm.price.transport = null;
                          }}
                        >
                          {convertCurrency(
                            brokerageForm.price.transport,
                            store.location
                          )}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Exhibit A: Configuration ID:{" "}
                      {brokerageForm.boat.configuration_id}
                    </td>
                    <td align="right"></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right"></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Price (excl. tax):</strong>
                    </td>
                    <td align="right">
                      <strong>
                        {convertCurrency(
                          brokerageForm.price.total,
                          store.location
                        )}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right"></td>
                  </tr>
                  <tr>
                    {store.broker_active_field === "discount" ? (
                      <React.Fragment>
                        <td>
                          <input
                            autoFocus
                            className="brokerage_input"
                            type="text"
                            style={{ textAlign: "left" }}
                            value={brokerageForm.discount.title}
                            onChange={(e) => {
                              brokerageForm.discount.title = e.target.value;
                            }}
                          />
                        </td>
                        <td align="right" style={{ color: "red" }}>
                          <input
                            autoFocus
                            className="brokerage_input"
                            type="number"
                            value={brokerageForm.discount.amount}
                            onChange={(e) => {
                              if (e.target.value)
                                brokerageForm.discount.amount = +e.target.value;
                              else brokerageForm.discount.amount = undefined;
                            }}
                          />
                        </td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <td onClick={() => store.unlock_field("discount")}>
                          {brokerageForm.discount.amount > 0 ? (
                            <span>{brokerageForm.discount.title}</span>
                          ) : (
                            <span></span>
                          )}
                        </td>
                        <td align="right" style={{ color: "red" }}>
                          <span onClick={() => store.unlock_field("discount")}>
                            {brokerageForm.discount.amount > 0 &&
                              convertCurrency(
                                -brokerageForm.discount.amount,
                                store.location
                              )}
                          </span>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                  <tr>
                    <td>
                      <strong>Subtotal:</strong>
                    </td>
                    <td align="right">
                      <strong>
                        {convertCurrency(
                          brokerageForm.price.subtotal,
                          store.location
                        )}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right"></td>
                  </tr>
                  <tr>
                    <td>
                      {store.broker_active_field === "tax" ? (
                        <React.Fragment>
                          <input
                            autoFocus
                            className="brokerage_input"
                            type="number"
                            value={brokerageForm.price.tax_percentage}
                            onBlur={(e) => {
                              if (
                                brokerageForm.price.tax_percentage === undefined
                              )
                                brokerageForm.price.tax_percentage = 0;
                            }}
                            onChange={(e) => {
                              if (e.target.value) {
                                brokerageForm.price.tax_percentage =
                                  +e.target.value;
                              } else
                                brokerageForm.price.tax_percentage = undefined;
                            }}
                          />{" "}
                          %
                        </React.Fragment>
                      ) : (
                        <span
                          onClick={() => {
                            store.unlock_field("tax");
                            if (brokerageForm.price.tax_percentage === 0)
                              brokerageForm.price.tax_percentage = 0;
                          }}
                        >
                          Sales Tax (
                          {Math.floor(brokerageForm.price.tax_percentage)}%)
                        </span>
                      )}
                    </td>
                    <td align="right">
                      {store.broker_active_field === "tax" ? (
                        <React.Fragment>
                          $
                          <input
                            autoFocus
                            className="brokerage_input"
                            type="number"
                            value={brokerageForm.price.tax}
                            onChange={(e) => {
                              if (e.target.value) {
                                brokerageForm.price.tax = +e.target.value;
                              } else brokerageForm.price.tax = undefined;

                              brokerageForm.price.tax_percentage =
                                (100 * brokerageForm.price.tax) /
                                brokerageForm.price.subtotal;
                            }}
                          />
                        </React.Fragment>
                      ) : (
                        <span
                          onClick={() => {
                            store.unlock_field("tax");
                            if (brokerageForm.price.tax_percentage === 0)
                              brokerageForm.price.tax_percentage = 0;
                          }}
                        >
                          {brokerageForm.price.tax
                            ? convertCurrency(
                                brokerageForm.price.tax,
                                store.location
                              )
                            : "NOT INCLUDED"}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td align="right"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table className="brokerage-summary">
                <tbody>
                  <tr>
                    <td>
                      <strong>Total Sale Price Due:</strong>
                    </td>
                    <td width="20%"></td>
                    <td width="10%"></td>
                    <td align="right" width="20%">
                      <strong>
                        {convertCurrency(
                          brokerageForm.price.total_due,
                          store.location
                        )}
                      </strong>
                    </td>
                  </tr>
                  {brokerageForm.payments.map((payment, i) => (
                    <tr key={i}>
                      <td>{payment.title}</td>
                      <td
                        onClick={() => store.unlock_field("payment_date" + i)}
                      >
                        {store.broker_active_field === "payment_date" + i ? (
                          <span>
                            <input
                              autoFocus
                              className="brokerage_input"
                              type="text"
                              placeholder="ex: Jun 2022"
                              value={payment.date}
                              onChange={(e) => {
                                store.brokerageUpdatePaymentDate(
                                  e.target.value,
                                  i
                                );
                              }}
                            />
                          </span>
                        ) : (
                          <span>{payment.date}</span>
                        )}
                      </td>
                      <td align="right">
                        {store.broker_active_field ===
                        "payment_percentage" + i ? (
                          <span>
                            <input
                              autoFocus
                              className="brokerage_input"
                              type="number"
                              value={payment.percentage}
                              onChange={(e) => {
                                store.brokerageUpdatePaymentPercentage(
                                  +e.target.value,
                                  i
                                );
                              }}
                            />
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              store.unlock_field("payment_percentage" + i)
                            }
                          >
                            {payment.percentage}%
                          </span>
                        )}
                      </td>
                      <td align="right">
                        {convertCurrency(payment.amount, store.location, true)}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td></td>
                    <td></td>
                    <td align="right" style={{ color: "#ccc" }}>
                      {store.brokerageForm.payment_percentage}%
                    </td>
                    <td align="right"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 50,
              }}
            >
              <button
                style={{ margin: 10, padding: "10px 20px", flex: 1 }}
                type="submit"
                className="button__clean"
                onClick={() => store.onBrokerageSalesAgreementCreated()}
              >
                CREATE SALES AGREEMENT
              </button>
              <button
                style={{ margin: 10, padding: "10px 20px", flex: 1 }}
                type="submit"
                className="button__clean"
                onClick={(e) => store.onBrokerSalesAgreementSendPdf(e)}
              >
                SEND SALES AGREEMENT AS PDF
              </button>
              <button
                disabled
                style={{
                  margin: 10,
                  padding: "10px 20px",
                  flex: 1,
                  color: "#ccc",
                  borderColor: "#ccc",
                }}
                type="submit"
                className="button__clean"
                onClick={() => store.onBrokerSalesAgreementSendDocusign()}
              >
                SEND TO DOCUSIGN
              </button>
            </div>
          </div>
        </div>
        <div className="options-wrapper">
          {store.summary.model.selected_picture && (
            <img
              className="image"
              src={`${store.summary.model.selected_picture}`}
              alt=""
            />
          )}
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: store.data.name }}
          ></div>

          <div className="upgrades-wrapper">
            <div className="option-group-title">ORDER SUMMARY</div>
            <div className="upgrades">
              {Object.keys(store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title !== "null" && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            store.summary.upgradeOptionsDisplay[step][
                              option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div
                                className="upgradeoption-option-group"
                                style={{
                                  marginLeft:
                                    option_group_title !== "null" ? 45 : 35,
                                }}
                              >
                                {option_group}
                              </div>

                              {store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title !== "null" ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div className="upgradeoption-option">
                                      {option.title}
                                    </div>
                                  </span>
                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      store.location
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation lang={store.page.lang} t="footer_purchase_price" />
              </div>
              <div className="">
                {convertCurrencySummaryLocation(
                  store.summary.purchase_price,
                  store.location
                )}
              </div>
            </div>

            {store.data.transportation && (
              <div
                className="purchase-price transportation"
                style={{ marginTop: 15 }}
              >
                <div style={{ fontSize: 14 }}>
                  <Translation t="Transportation Fee" lang={store.page.lang} />{" "}
                  *
                </div>
                <div className="">
                  {convertCurrencySummaryLocation(
                    store.data.transportation,
                    store.location
                  )}
                </div>
              </div>
            )}

            <div className="purchase-price total" style={{ marginTop: 15 }}>
              <div>
                <strong>
                  <Translation t="Total Price" lang={store.page.lang} />
                </strong>
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  store.summary.purchase_price +
                    (store.data.transportation
                      ? store.data.transportation.eur
                      : 0),
                  store.location
                )}
              </div>
            </div>

            <div style={{ marginTop: 25, fontSize: 12 }}>
              <Translation t="TRANSPORTATION_INFO" lang={store.page.lang} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BrokerStep;
