import { toJS } from "mobx";
import React from "react";

export const CheckBox = (props) => {
  const [status, setStatus] = React.useState(props.value ? "ok" : "fail");

  return (
    <div className="checkbox">
      <div
        className={`checkbox-ok ${status === "ok" ? "selected" : ""}`}
        onClick={() => {
          setStatus("ok");
          props.onChange && props.onChange(true);
        }}
      >
        OK
      </div>
      <div
        className={`checkbox-not ${status === "fail" ? "selected" : ""}`}
        onClick={() => {
          setStatus("fail");
          props.onChange && props.onChange(false);
        }}
      >
        FAIL
      </div>
    </div>
  );
};

export const CheckListItem = (props) => {
  return (
    <tr>
      <td style={{ width: "10%" }}>
        <CheckBox
          onChange={(v) => (props.object[props.field + "_c"] = v)}
          value={props.object[props.field + "_c"]}
        />
      </td>
      <td>
        <span style={{ padding: 5, fontSize: 12 }}>{props.text}</span>
      </td>
      <td style={{ width: "50%" }}>
        <input
          type="text"
          style={{ width: "100%" }}
          onChange={(e) => (props.object[props.field] = e.target.value)}
          value={props.object[props.field]}
        />
      </td>
    </tr>
  );
};
