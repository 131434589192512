import { observable, action, toJS, computed } from "mobx";
import Axios from "axios";
import { appConfig } from "./config";
import _ from "lodash";

const order_map = ["Initial", "Second", "Third"];
const date_map = ["", "", "", ""];
const percentage_map = [30, 40, 30];

// const title_map = [
//   "Due at Signing",
//   "Due at Production Start",
//   "Due at Production Completion",
//   "At Delivery",
// ];

const api = Axios.create({
  baseURL: appConfig.api,
});

api.interceptors.request.use((config) => {
  // config.params["nocache"] = new Date().getTime();
  return config;
});

export class Store {
  @observable trackmouse = null;
  @observable builds = [];
  @observable filtered_builds = [];
  @observable buildPage = { search: "" };
  @observable broker_active_field = "";
  @observable payment = {};
  @observable location_name = "";
  @observable location = {};
  @observable locations = {};
  @observable configuration = {};
  @observable list = [];
  @observable selectedModel = undefined;
  @observable selectAnimate = false;
  @observable user = undefined;
  @observable cover_options = [];
  @observable image_mode = "exterior1";
  @observable builds_sort = "";
  @observable builds_sort_direction = "asc";
  @observable galelry = [];

  @action set_image_mode = (e, mode) => {
    e.preventDefault();
    this.image_mode = mode;
  };

  @action unlock_field = (name) => {
    this.broker_active_field = name;
  };

  @action track_mouse_click = () => {
    if (!this.trackmouse) {
      document.addEventListener("click", (e) => {
        if (this.page.stepIndex === this.page.brokerStep) {
          if (
            this.broker_active_field !== "" &&
            e.target.className !== "brokerage_input"
          ) {
            this.broker_active_field = "";
            this.calculateBrokeragePrice();
          }
        }
      });
    }
  };

  @observable
  page = {
    lang: "en",
    suffix: "",
    loading: true,
    selectedStep: null,
    completed: false,
    completionType: "", // pdf | payment
    stepIndex: 0,
    summaryStep: 0,
    pdfStep: 0,
    paymentStep: 0,
    completeStep: 0,
    brokerStep: 0,
    factoryStep: 0,
    error: "",
    hideSteps: false,
    order_number: "",
    original_picture: undefined,
    pdf_path: undefined,
    nextEnabled: false,
    power: {
      unit: "hp",
      value: 0,
    },
    speed: {
      unit: "kn",
      value: 0,
    },
  };

  @observable
  dependencyPopup = {
    visible: false,
    dependant: {},
    dependencies: [],
  };

  @observable
  dependantsPopup = {
    visible: false,
    dependant: {},
    dependencies: [],
  };

  @observable
  detailsForm = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    boating: "",
    delivery: "",
    address: "",
  };

  @observable
  paymentForm = {
    name: "",
    cardnumber: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
    zipcode: "",
  };

  @observable factoryForm = {
    year: "",
    date_of_order: "",
    hull_number: "",
    customer_name: "",
    delivery_address: "",
    delivery: "",
    electrical_system: "",
    contact_person: "",
    contact_phone: "",
    contact_email: "",
    factory_notes: "",
    revision: 1,
  };

  @observable brokerageForm = {
    manager: {
      name: "Onur Erardağ",
      email: "onur.erardag@saxdorturkey.com",
    },
    buyer: {
      // form
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      business_phone: "",
      referred_by: "",
      address: "",
      preferred_delivery_date: "",
      identity: "",
    },
    boat: {
      // configurator
      configuration_id: "",
      hull_number: "",
      production_number: "",
      date: "",
      make: "",
      boat_length: "",
      model: "",
      power: "", // engine
      fob: "", // ?
      sales_associate: "",
      extras: "",
      slot_number: "",
      built_year: "",
      model_year: "",
      flag: "",
      order_date: "",
    },
    price: {
      base_price: 0, // engine
      additional_price: 0, // selection
      rigging: 0, // form
      extra_cost: 0, // form
      transport: 0, // form
      total: 0, // form
      subtotal: 0, // price - discount
      total_due: 0, // discounted price
      tax: 0,
      tax_percentage: 0,
    },
    discount: {
      // form
      title: "",
      amount: 0,
    },
    payments: [],
    payment_percentage: 0,
  };

  @action calculateBrokeragePrice = () => {
    const { price, discount } = this.brokerageForm;

    if (!price.rigging) price.rigging = 0;
    if (!price.extra_cost) price.extra_cost = 0;
    if (!price.transport) price.transport = 0;

    price.total =
      price.base_price +
      price.additional_price +
      price.rigging +
      price.extra_cost +
      price.transport;

    price.subtotal = price.total - discount.amount;
    price.tax = (price.tax_percentage / 100) * price.subtotal;
    price.total_due = price.subtotal + price.tax;

    this.brokerageForm.payment_percentage = 0;

    for (var x = 0; x < this.brokerageForm.payments.length; x++) {
      var payment = this.brokerageForm.payments[x];
      var order = order_map[x];
      var percentage = payment.percentage;
      var date = payment.date;

      var amount =
        (this.brokerageForm.price.total_due * payment.percentage) / 100;
      payment.amount = amount;

      this.brokerageForm.payment_percentage += payment.percentage;

      payment.full_title =
        `${order} Payment ${percentage}% ` + (date ? ` (${date})` : "");
    }
  };

  @observable lastPrice = 0;

  @observable data = { steps: [] };

  @observable state = "configurator"; // configurator | summary | payment | pdf

  @action getLocation = (location) => {
    this.location_name = location;

    api
      .get(`/locations`, {
        params: {
          name: location,
        },
      })
      .then((r) => {
        this.location = r.data[0];
        this.factoryForm.electrical_system = this.location.electrical_system;
      })
      .catch((e) => console.log(e));
  };

  @action loadConfig = () => {
    api
      .get(`/configuration`)
      .then((r) => (this.configuration = r.data))
      .catch((e) => console.log(e));

    const user_str = localStorage.getItem("configurator:user");
    if (user_str) {
      this.user = JSON.parse(user_str);
    }

    this.track_mouse_click();
  };

  @action getLocations = () => {
    api
      .get("/locations")
      .then((r) => {
        this.locations = r.data;
        this.page.loading = false;
      })
      .catch((e) => console.log(e));
  };

  @action logout = () => {
    localStorage.removeItem("configurator:user");
    window.location.reload();
  };

  @action getModels = (exclusive = false) => {
    if (exclusive) {
      api.get(`/models/exclusive`)
        .then((r) => {
          this.list = r.data;
          this.page.loading = false;
        })
        .catch((e) => console.log(e));
    } else {
      api.get(`/models`)
        .then((r) => {
          this.list = r.data;
          this.page.loading = false;
        })
        .catch((e) => console.log(e));
    }
  };

  @action getBuilds = () => {
    api
      .get(`/payments/bylocation`, {
        params: { location: this.location_name },
      })
      .then((r) => {
        this.builds = r.data.reverse();

        this.filtered_builds = this.builds.map((x) => ({
          ...x,
          status: x.status ? x.status : "waiting",
        }));

        this.page.loading = false;
      });
  };

  @observable service_data = {};

  @observable service_gallery = {
    engine: [],
    electrical: [],
    furniture: [],
  };

  @observable service = {
    number: null,
    payment: null,
    motor_kontrolleri_c: null,
    motor_kontrolleri: null,
    motor_kayis_kontrolleri_c: null,
    motor_kayis_kontrolleri: null,
    motor_th_baglanto_kontrolleri_c: null,
    motor_th_baglanto_kontrolleri: null,
    motor_testleri_c: null,
    motor_testleri: null,
    mercury_jpo_c: null,
    mercury_jpo: null,
    motor_baglanti_civatalari_c: null,
    motor_baglanti_civatalari: null,
    batarya_and_aku_sarj_kontrolleri_sahil_besleme_c: null,
    batarya_and_aku_sarj_kontrolleri_sahil_besleme: null,
    silecek_kontrolu_c: null,
    silecek_kontrolu: null,
    aydinlatma_kontrolu_c: null,
    aydinlatma_kontrolu: null,
    irgat_c: null,
    irgat: null,
    bas_pervane_c: null,
    bas_pervane: null,
    teras_acilma_kapama_c: null,
    teras_acilma_kapama: null,
    teras_kilit_c: null,
    teras_kilit: null,
    sahil_baglanti_hatti_c: null,
    sahil_baglanti_hatti: null,
    redresor_c: null,
    redresor: null,
    buzdolabi_c: null,
    buzdolabi: null,
    simrad_ekran_c: null,
    simrad_ekran: null,
    flap_sistemi_c: null,
    flap_sistemi: null,
    ses_sistemi_c: null,
    ses_sistemi: null,
    radar_kontrolleri_c: null,
    radar_kontrolleri: null,
    simrad_birim_ayarlari_c: null,
    simrad_birim_ayarlari: null,
    tum_mobilyalarin_vida_baglantilari_vb_kontrolleri_c: null,
    tum_mobilyalarin_vida_baglantilari_vb_kontrolleri: null,
    ayna_c: null,
    ayna: null,
    kapi_c: null,
    kapi: null,
    kapak_c: null,
    kapak: null,
    mutfak_gideri_c: null,
    mutfak_gideri: null,
    wc_giderleri_c: null,
    wc_giderleri: null,
    pis_su_giderleri_c: null,
    pis_su_giderleri: null,
    tum_borularin_akinti_kontrolleri_ve_kelepceleri_c: null,
    tum_borularin_akinti_kontrolleri_ve_kelepceleri: null,
    kapi_menteselerinin_kontrolleri_c: null,
    kapi_menteselerinin_kontrolleri: null,
    teras_mentese_kontrolleri_c: null,
    teras_mentese_kontrolleri: null,
    on_kapi_su_girme_kontrolu_c: null,
    on_kapi_su_girme_kontrolu: null,
    kamera_hachlerinin_su_sizdirma_kontrolleri_c: null,
    kamera_hachlerinin_su_sizdirma_kontrolleri: null,
    cam_kontrolleri_c: null,
    cam_kontrolleri: null,
    sika_kontrolu_c: null,
    sika_kontrolu: null,
    doseme_kontrolleri_c: null,
    doseme_kontrolleri: null,
    pvc_teak_c: null,
    pvc_teak: null,
    su_tanklarinin_kontrolleri_c: null,
    su_tanklarinin_kontrolleri: null,
    govde_genel_kontrolu_c: null,
    govde_genel_kontrolu: null,
    yakit_ve_yakit_tanki_kontrolu_c: null,
    yakit_ve_yakit_tanki_kontrolu: null,
    gelcoat_kontrolu_c: null,
    gelcoat_kontrolu: null,
    su_hatti_c: null,
    su_hatti: null,
  };

  @observable configuration_gallery = [];

  @action getServiceList = () => {
    api
      .get(`/services/list`, {
        headers: {
          Authorization: "Bearer " + this.user.jwt,
        },
      })
      .then((r) => {
        this.builds = r.data.reverse();

        this.filtered_builds = this.builds.map((x) => ({
          ...x,
          status: x.status ? x.status : "waiting",
        }));

        this.page.loading = false;
      });
  };

  @action getServiceDetail = async (id) => {
    api
      .get(`/services/${id}`, {
        headers: {
          Authorization: "Bearer " + this.user.jwt,
        },
      })
      .then((r) => {
        this.service_data = r.data;

        if (this.service_data.service) {
          this.service = this.service_data.service;
        } else {
          this.service.payment = this.service_data.id;
        }

        if (this.service_data.configuration_check) {
          this.configuration_data = this.service_data.configuration_check;
        } else {
          this.configuration_data = this.service_data.raw.map((i) => ({
            title: i.title,
            sku: i.sku,
            value: "",
            value_c: false,
          }));
        }

        this.page.loading = false;
      });
  };

  @action onServiceSave = async () => {
    this.page.loading = true;

    var formdata = new FormData();
    formdata.set("data", JSON.stringify(this.service));

    if (!this.service.id) {
      api
        .post("/services", formdata)
        .then(async (r) => {
          for (let i = 0; i < this.service_gallery.engine.length; i++) {
            let file = this.service_gallery.engine[i];

            const bodyFormData = new FormData();

            bodyFormData.append("ref", "service");
            bodyFormData.append("refId", r.data.id);
            bodyFormData.append("field", "motor_kontrolleri_galeri");
            bodyFormData.append(`files`, file);
            bodyFormData.append(
              "fileInfo",
              `{"caption":"","alternativeText":"","name":"${file.name}"}`
            );

            await api.post("/upload", bodyFormData);
          }

          for (let i = 0; i < this.service_gallery.electrical.length; i++) {
            let file = this.service_gallery.electrical[i];

            const bodyFormData = new FormData();

            bodyFormData.append("ref", "service");
            bodyFormData.append("refId", r.data.id);
            bodyFormData.append("field", "elektrik_sistemleri_galeri");
            bodyFormData.append(`files`, file);
            bodyFormData.append(
              "fileInfo",
              `{"caption":"","alternativeText":"","name":"${file.name}"}`
            );

            await api.post("/upload", bodyFormData);
          }

          for (let i = 0; i < this.service_gallery.furniture.length; i++) {
            let file = this.service_gallery.furniture[i];

            const bodyFormData = new FormData();

            bodyFormData.append("ref", "service");
            bodyFormData.append("refId", r.data.id);
            bodyFormData.append("field", "mobilyalar_galeri");
            bodyFormData.append(`files`, file);
            bodyFormData.append(
              "fileInfo",
              `{"caption":"","alternativeText":"","name":"${file.name}"}`
            );

            await api.post("/upload", bodyFormData);
          }

          alert("Kayıt işlemi başarılı.");
          window.location.reload();
          this.page.loading = false;
        })
        .catch((e) => {
          this.page.loading = false;
          console.log(e);
        });
    } else {
      api
        .put(`/services/${this.service.payment}`, formdata)
        .then(async (r) => {
          for (let i = 0; i < this.service_gallery.engine.length; i++) {
            let file = this.service_gallery.engine[i];

            const bodyFormData = new FormData();

            bodyFormData.append("ref", "service");
            bodyFormData.append("refId", r.data.id);
            bodyFormData.append("field", "motor_kontrolleri_galeri");
            bodyFormData.append(`files`, file);
            bodyFormData.append(
              "fileInfo",
              `{"caption":"","alternativeText":"","name":"${file.name}"}`
            );

            await api.post("/upload", bodyFormData);
          }

          for (let i = 0; i < this.service_gallery.electrical.length; i++) {
            let file = this.service_gallery.electrical[i];

            const bodyFormData = new FormData();

            bodyFormData.append("ref", "service");
            bodyFormData.append("refId", r.data.id);
            bodyFormData.append("field", "elektrik_sistemleri_galeri");
            bodyFormData.append(`files`, file);
            bodyFormData.append(
              "fileInfo",
              `{"caption":"","alternativeText":"","name":"${file.name}"}`
            );

            await api.post("/upload", bodyFormData);
          }

          for (let i = 0; i < this.service_gallery.furniture.length; i++) {
            let file = this.service_gallery.furniture[i];

            const bodyFormData = new FormData();

            bodyFormData.append("ref", "service");
            bodyFormData.append("refId", r.data.id);
            bodyFormData.append("field", "mobilyalar_galeri");
            bodyFormData.append(`files`, file);
            bodyFormData.append(
              "fileInfo",
              `{"caption":"","alternativeText":"","name":"${file.name}"}`
            );

            await api.post("/upload", bodyFormData);
          }

          alert("Kayıt işlemi başarılı.");
          window.location.reload();
          this.page.loading = false;
        })
        .catch((e) => {
          this.page.loading = false;
          console.log(e);
        });
    }
  };

  @action onConfigurationCheckSave = async () => {
    this.page.loading = true;

    const result = await api.post("/services/configurator", this.service_data);

    this.page.loading = false;
    alert("Kayıt işlemi başarılı.");
  };

  @action onSortBuilds = (e, key) => {
    e.preventDefault();

    let current = this.builds_sort === key;
    this.builds_sort = key;

    if (current) {
      if (this.builds_sort_direction === "asc")
        this.builds_sort_direction = "desc";
      else this.builds_sort_direction = "asc";
    } else {
      this.builds_sort_direction = "asc";
    }

    // console.log("Sorting ", this.builds_sort, this.builds_sort_direction);

    this.filtered_builds = _.orderBy(
      this.filtered_builds,
      [this.builds_sort],
      [this.builds_sort_direction]
    );
  };

  @action onFilterBuilds = (text) => {
    var regex = new RegExp(text, "gi");

    this.filtered_builds = this.builds.filter((x) => {
      return (
        (x.order_number && x.order_number.search(regex) > -1) ||
        (x.firstname && x.firstname.search(regex) > -1) ||
        (x.lastname && x.lastname.search(regex) > -1) ||
        (x.email && x.email.search(regex) > -1)
      );
    });
  };

  @action onStatusChanged = async (id, status) => {
    const result = await api.post(`/payments/${id}/update-status`, { status });

    console.log(result.data);
  };

  @observable selectedStatus = "";

  @action selectModel = (model) => {
    if (this.selectedModel && model.id === this.selectedModel.id) return;
    this.selectAnimate = true;
    this.selectedModel = model;

    if (this.selectedModel.standards_rich) {
      try {
        var parts = this.selectedModel.standards_rich.split(">>");
        var items = [];

        parts.map((x) => {
          var xxx = x.split("--");
          items.push([xxx[0].replaceAll("\n", ""), xxx[1].replace("\n", "")]);

          return xxx;
        });

        this.selectedModel.tabs = items;
      } catch { }
    }

    setTimeout(() => (this.selectAnimate = false), 1000);
  };

  @action
  init = (slug, number) => {
    api
      .get(`/models/byslug/${slug}`)
      .then(async (r) => {
        this.data = r.data;
        this.gallery = r.data.gallery;

        var selections = [];

        try {
          if (number) {
            await api.get(`/payments/bynumber/${number}`).then((r) => {
              this.payment = r.data;

              this.page.order_number = r.data.order_number;
              this.detailsForm.firstname = r.data.firstname;
              this.detailsForm.lastname = r.data.lastname;
              this.detailsForm.boating = r.data.boating;
              this.detailsForm.delivery = r.data.delivery;
              this.detailsForm.phone = r.data.phone;
              this.detailsForm.email = r.data.email;
              this.detailsForm.address = r.data.address;

              this.factoryForm.number = number;
              this.factoryForm.revision = r.data.revision;
              this.factoryForm.hull_number = r.data.hull_number;
              this.factoryForm.year = r.data.year;
              this.factoryForm.date_of_order = r.data.date_of_order;
              this.factoryForm.delivery = r.data.delivery;
              this.factoryForm.delivery = r.data.delivery;

              if (r.data.firstname) {
                this.factoryForm.customer_name =
                  r.data.firstname + " " + r.data.lastname;
              }

              this.factoryForm.delivery_address = r.data.delivery_address;
              this.factoryForm.electrical_system = r.data.electrical_system;
              this.factoryForm.contact_person = r.data.contact_person;
              this.factoryForm.contact_email = r.data.contact_email;
              this.factoryForm.contact_phone = r.data.contact_phone;
              this.factoryForm.factory_notes = r.data.factory_notes || "";

              selections = r.data.raw.map((x) => `${x.id}`);
            });
          } else {
            if (window.location.search !== "") {
              var parts = window.location.search.split("=");
              if (parts.length > 0) {
                selections = atob(parts[1].replaceAll("-", "="))
                  .split(",")
                  .map((x) => x);
              }
            }
          }
        } catch (e) { }

        window.steps = toJS(r.data.steps);
        this.data.steps = this.data.steps.sort((x, y) => x.sorting - y.sorting);

        this.data.steps.forEach((step) => {
          step.option_groups = step.option_groups.sort(
            (x, y) => y.sorting - x.sorting
          );

          step.option_groups.forEach((option_group) => {
            if (option_group.has_additional) {
              option_group.options.push({
                id: 9999,
                selected: false,
                title: "",
                additional: true,
                price: {
                  usd: 1,
                },
              });
            }

            option_group.options = option_group.options.sort(
              (x, y) => y.sorting - x.sorting
            );

            option_group.options.forEach((option) => {
              // check cover_picture if any add to cover arr
              var exists = selections.find(
                (i) => parseInt(i.split(":")[0]) === option.id
              );

              if (exists) {
                this.selectOption(option, option_group);
                var parts = exists.split(":");
                if (parts.length > 1) {
                  var title = parts[1];
                  option.title = title;
                  option.specified_value = title;
                }
              }

              if (option.power && option.speed && option.selected) {
                if (option.power) {
                  let parts = option.power.split(" ");
                  this.page.power.value = parts[0];
                  this.page.power.unit = parts[1];
                }

                if (option.speed) {
                  let parts = option.speed.split(" ");
                  this.page.speed.value = parts[0];
                  this.page.speed.unit = parts[1];
                }
              }
            });
          });
        });

        if (this.data.standards_rich) {
          try {
            let parts = this.data.standards_rich.split(">>");
            var items = [];

            parts.forEach((x) => {
              var xxx = x.split("--");
              items.push([
                xxx[0].replaceAll("\n", ""),
                xxx[1].replace("\n", ""),
              ]);
            });

            this.data.tabs = items;
          } catch { }
        }

        this.page.hideSteps = false;
        this.page.summaryStep = r.data.steps.length;
        this.page.pdfStep = r.data.steps.length + 1;
        this.page.paymentStep = r.data.steps.length + 2;
        this.page.brokerStep = r.data.steps.length + 3;
        this.page.factoryStep = r.data.steps.length + 4;
        this.page.completeStep = r.data.steps.length + 5;
        this.page.original_picture = r.data.picture;
        this.page.loading = false;

        if (slug.includes("inventory")) {
          this.page.stepIndex = r.data.steps.length;
          this.page.hideSteps = true;
        } else {
          // this.page.stepIndex = 0;
          this.onStepSelected(this.data.steps[0], 0);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  @action popupClose = () => {
    this.dependencyPopup.visible = false;
  };

  @action popupCloseDependant = () => {
    this.dependantsPopup.visible = false;
  };

  @action
  onStepSelected = (step, i) => {
    let interior_index = window.steps.findIndex((x) => x.title === "INTERIOR");

    this.image_mode = interior_index === i ? "interior1" : "exterior1";

    this.page.stepIndex = i;
    this.page.selectedStep = step;

    if (step.check_scroll) {
      this.page.nextEnabled = false;
    } else {
      this.page.nextEnabled = true;
    }

    setTimeout(() => {
      if (step.target) {
        document.getElementById(step.target).scrollIntoView();
      } else {
        var groups = document.querySelectorAll(".options-wrapper");
        groups.forEach((x) => (x.scrollTop = 0));
      }
    }, 100);
  };

  @action brokerageUpdatePaymentDate = (date, i) => {
    this.brokerageForm.payments[i].date = date;
  };

  @action brokerageUpdatePaymentPercentage = (percentage, i) => {
    this.brokerageForm.payments[i].percentage = percentage;
  };

  @action onBrokerageSalesAgreementCreated = async () => {
    this.page.loading = true;

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/brokerage/pdf`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        console.log(r.data);
        window.open(r.data.url);
        window.open(r.data.configurator_url);
      })
      .catch((e) => {
        this.page.loading = false;
        alert("An error occured.");
        console.log(e);
      });
  };

  @action onBrokerSalesAgreementSendPdf = async (e) => {
    e.preventDefault();
    this.page.loading = true;
    const { configuration_id } = this.brokerageForm.boat;

    if (!this.brokerageForm.buyer.email) {
      alert("You must provide the buyer's E-mail Address to send pdf");
    }

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/sales-agreement/${configuration_id}/send`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        alert("Pdf sent to buyer.");
      })
      .catch((e) => {
        this.page.loading = false;
        alert("An error occured.");
      });
  };

  @action onBrokerSalesAgreementSendDocusign = async () => {
    console.log("onBrokerSalesAgreementSendDocusign");
  };

  @action onBrokerPdfSubmit = async (e) => {
    e.preventDefault();
    this.page.loading = true;
    const { configuration_id } = this.brokerageForm.boat;

    if (!this.brokerageForm.buyer.email) {
      alert("You must provide the buyer's E-mail Address to send pdf");
    }

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/brokerage/pdf/${configuration_id}/send`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        alert("Pdf has sent.");
        console.log(r.data);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action onBrokerPdfDownload = async (e) => {
    e.preventDefault();
    this.page.loading = true;
    const { configuration_id } = this.brokerageForm.boat;

    if (!this.brokerageForm.buyer.email) {
      alert("You must provide the buyer's E-mail Address to send pdf");
    }

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api
      .post(
        `/payments/brokerage/pdf/${configuration_id}/download`,
        {
          brokerage: toJS(this.brokerageForm),
        },
        config
      )
      .then((r) => {
        this.page.loading = false;
        window.open(r.data.pdf);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onBrokerageStepSelected = (step, i) => {
    // save configuration
    this.page.loading = true;
    // this.brokerageForm.boat.sales_associate = this.user.user.fullname;

    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    api.post(`/payments/brokerage`, this.summary, config).then((r) => {
      const result = r.data;

      const engine = result.raw.find((x) => x.power);
      const additional = result.raw.filter((x) => !x.power);

      let additional_price = 0;

      if (additional.length > 0) {
        additional_price = additional
          .map((x) => x.price[this.location.currency])
          .reduce((x, y) => (x > 0 ? x : 0) + (y > 0 ? y : 0));
      }

      this.brokerageForm.boat.model = result.flat_name;
      this.brokerageForm.boat.configuration_id = result.order_number;

      if (engine) {
        this.brokerageForm.boat.power = engine.title;
        this.brokerageForm.price.base_price =
          engine.price[this.location.currency];
        this.brokerageForm.price.additional_price =
          additional_price > 0 ? additional_price : 0;

        this.brokerageForm.price.transport =
          this.data.transportation[this.location.currency];

        this.brokerageForm.price.total =
          result.purchase_price + this.brokerageForm.price.transport;
        this.brokerageForm.price.subtotal =
          result.purchase_price + this.brokerageForm.price.transport;
        this.brokerageForm.price.tax =
          (this.brokerageForm.price.tax_percentage / 100) *
          this.brokerageForm.price.total;

        this.brokerageForm.price.total_due =
          this.brokerageForm.price.total + this.brokerageForm.price.tax;
      }

      this.brokerageForm.payments = [];
      this.brokerageForm.payment_percentage = 0;

      for (var x = 0; x < 3; x++) {
        var order = order_map[x];
        var percentage = percentage_map[x];
        var date = date_map[x];
        var amount = (this.brokerageForm.price.total_due * percentage) / 100;

        var payment = {
          full_title:
            `${order} Payment ${percentage}% ` + (date ? ` (${date})` : ""),
          title: `${order} Payment `,
          percentage: percentage,
          amount: amount,
          date: date,
        };

        this.brokerageForm.payments.push(payment);
        this.brokerageForm.payment_percentage += payment.percentage;
      }

      this.page.loading = false;
      this.page.order_number = r.data.order_number;
      this.page.stepIndex = i;
      this.page.selectedStep = step;
      this.page.nextEnabled = true;
    });

    setTimeout(() => {
      if (step.target) {
        document.getElementById(step.target).scrollIntoView();
      } else {
        var groups = document.querySelectorAll(".options-wrapper");
        groups.forEach((x) => (x.scrollTop = 0));
      }
    }, 100);
  };

  @action
  nextStep = () => {
    this.page.stepIndex++;

    const currentStep = this.data.steps[this.page.stepIndex];

    if (currentStep && currentStep.check_scroll) {
      this.page.nextEnabled = false;
    } else {
      this.page.nextEnabled = true;
    }

    setTimeout(() => {
      var groups = document.querySelectorAll(".options-wrapper");
      groups.forEach((x) => (x.scrollTop = 0));
    }, 100);
  };

  @action
  prevStep = () => {
    this.page.stepIndex--;

    setTimeout(() => {
      var groups = document.querySelectorAll(".options-wrapper");
      groups.forEach((x) => (x.scrollTop = 0));
    }, 100);
  };

  @computed get checkNext() {
    const currentStep = this.data.steps[this.page.stepIndex];

    if (!currentStep || !currentStep.required) return true;

    const allselected = currentStep.option_groups
      .filter((x) => x.required)
      .every((og) => og.options.some((x) => x.selected));

    return allselected;
  }

  @action
  onFactorySubmit = (e) => {
    e.preventDefault();
    let config = {};

    console.log(toJS(this.user));
    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    this.page.loading = true;
    this.page.error = "";

    const req = {
      factoryForm: this.factoryForm,
      summary: this.summary,
    };

    api
      .post(`/payments/factory`, req, config)
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data.order_number;
        this.page.pdf_path = r.data.pdf;

        this.page.completed = true;
        this.page.completionType = "pdf";

        window.open(
          `${appConfig.api}/order/view/factory_order_form_${this.page.order_number}.xlsx`
        );
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onPdfSubmit = (e) => {
    e.preventDefault();
    let config = {};

    if (this.user) {
      config.headers = {
        Authorization: "Bearer " + this.user.jwt,
      };
    }

    this.page.loading = true;
    this.page.error = "";

    if (this.page.order_number) {
      console.log(this.page.order_number);

      api
        .post(`/payments/${this.page.order_number}/pdf`, this.summary, config)
        .then((r) => {
          this.page.loading = false;
          this.page.order_number = r.data.order_number;
          this.page.pdf_path = r.data.pdf;

          this.page.completed = true;
          this.page.completionType = "pdf";
          this.onStepSelected({}, this.page.completeStep);
        })
        .catch((e) => {
          this.page.loading = false;
          console.log(e);
        });
    } else {
      api
        .post(`/payments/pdf`, this.summary, config)
        .then((r) => {
          this.page.loading = false;
          this.page.order_number = r.data.order_number;
          this.page.pdf_path = r.data.pdf;

          this.page.completed = true;
          this.page.completionType = "pdf";
          this.onStepSelected({}, this.page.completeStep);
        })
        .catch((e) => {
          this.page.loading = false;
          console.log(e);
        });
    }
  };

  @action
  onPdfDownload = (e) => {
    e.preventDefault();

    if (this.page.order_number) {
      window.open(
        `${appConfig.api}/payments/pdf/${this.page.order_number}/download`
      );
      return;
    }

    this.page.loading = true;
    this.page.error = "";

    api
      .post(`/payments/pdf/download`, this.summary)
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data.order_number;

        window.open(r.data);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onPaymentSubmit = (e) => {
    e.preventDefault();

    this.page.loading = true;
    this.page.error = "";

    api
      .post(`/payments/make`, this.summary)
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data;

        if (r.data.stripe_error) {
          this.page.error = "Payment failed: " + r.data.stripe_error;
          return;
        }

        this.page.completed = true;
        this.page.completionType = "payment";
        this.onStepSelected({}, this.page.completeStep);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  selectOption = (option, group, required = false) => {
    if (group.required && option.selected) return;
    if (option.package) return;

    this.lastPrice = this.summary.purchase_price;
    const step = this.data.steps.find((x) => x.id === group.step);
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);
    const options_map = option_group_map.flatMap((x) => x.options);

    if (option.power) {
      var powerparts = option.power.split(" ");
      this.page.power.value = powerparts[0];
      this.page.power.unit = powerparts[1];
    }

    if (option.speed) {
      var speedparts = option.speed.split(" ");
      this.page.speed.value = speedparts[0];
      this.page.speed.unit = speedparts[1];
    }

    // check depenendcy
    const dependants = options_map.filter(
      (o) =>
        o.dependency &&
        o.dependency.find((d) => d.option === option.id) &&
        o.selected
    );

    if (dependants.length > 0) {
      // show remove popup
      console.log("remove selected items");

      this.dependantsPopup.visible = true;
      this.dependantsPopup.dependant = option;
      this.dependantsPopup.dependencies = dependants;
      return;
    }

    if (option.dependency && option.dependency.length > 0 && !option.selected) {
      var dependencyFound = false;
      var dependencyList = [];

      for (let i = 0; i < option.dependency.length; i++) {
        const dependent = this.summary.upgradeOptions.find(
          (x) => x.id === option.dependency[i].option.id
        );

        const dependencyOption = options_map.find(
          (x) => x.id === option.dependency[i].option.id
        );

        if (dependencyOption) {
          dependencyList.push(dependencyOption);
        }

        if (dependent && dependent.selected) {
          dependencyFound = true;
        }
      }

      if (!dependencyFound) {
        this.dependencyPopup.visible = true;
        this.dependencyPopup.dependant = option;
        this.dependencyPopup.dependencies = dependencyList;
        return;
      }
    }

    if (
      group.type === "single" ||
      group.type === "singleImage" ||
      group.type === "picker"
    ) {
      if (!option.selected) {
        group.options.forEach((xo) => {
          xo.selected = false;

          if (xo.related && xo.related.option_group) {
            var ogs = step.option_groups.find(
              (x) => x.id === xo.related.option_group.id
            );

            if (ogs) {
              ogs.options.forEach((x) => (x.selected = false));
            }
          }
        });
        option.selected = true;
      } else {
        if (!required) option.selected = false;
      }

      if (option.related && option.related.option_group) {
        var ogs = step.option_groups.find(
          (x) => x.id === option.related.option_group.id
        );

        if (ogs) {
          ogs.options.forEach((x) => (x.selected = false));
        }
      }
    } else {
      option.selected = !option.selected;
    }

    if (option.related_option) {
      option.related_option.options.forEach((ro) => {
        const roog = option_group_map.find((x) => x.id === ro.option_group);

        if (roog) {
          const ritem = roog.options.find((x) => x.id === ro.id);
          ritem.selected = false;

          if (ritem.related && ritem.related.option_group) {
            var ogs = step.option_groups.find(
              (x) => x.id === ritem.related.option_group.id
            );

            if (ogs) {
              ogs.options.forEach((x) => (x.selected = false));
            }
          }
        }
      });
    }

    var upgradeOptions = this.data.steps.flatMap((x) =>
      x.option_groups.flatMap((og) => og.options)
    );

    if (option.package_content) {
      for (let i = 1; i < upgradeOptions.length; i++) {
        let uoption = upgradeOptions[i];

        if (
          option.package_content.package_content_items.some(
            (x) => x.id === uoption.id
          )
        ) {
          uoption.selected = false;
          uoption.package = option.selected;
          uoption.package_name = option.selected
            ? option[this.langify("title")]
            : undefined;
        }
      }
    }

    if (option.cover_picture) {
      if (option.selected) {
      } else {
      }
    }
  };

  @action selectDependency = (dependency) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const dependency_group = option_group_map.find(
      (x) => x.id === dependency.option_group
    );

    const dependant_group = option_group_map.find(
      (x) => x.id === this.dependencyPopup.dependant.option_group
    );

    this.selectOption(dependency, dependency_group);
    this.selectOption(this.dependencyPopup.dependant, dependant_group);

    this.dependencyPopup = {
      visible: false,
      dependant: {},
      dependencies: [],
    };
  };

  @action removeDependants = () => {
    this.dependantsPopup.dependant.selected = false;
    this.dependantsPopup.dependencies.map((x) => (x.selected = false));

    this.dependantsPopup = {
      visible: false,
      dependant: {},
      dependencies: [],
    };
  };

  @action
  checkOptionRemovable = (option) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const option_group = option_group_map.find(
      (x) => x.id === option.option_group
    );

    return !option_group.required;
  };

  @action
  removeOption = (option) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const option_group = option_group_map.find(
      (x) => x.id === option.option_group
    );

    if (!option_group.required) {
      const found = option_group.options.find((x) => x.id === option.id);
      found.selected = false;
    }
  };

  @action
  goToOption = (option) => {
    const option_group_map = this.data.steps.flatMap((x) => x.option_groups);

    const option_group = option_group_map.find(
      (x) => x.id === option.option_group
    );

    const step = this.data.steps.find((x) => x.id === option_group.step);
    const index = this.data.steps.indexOf(step);

    this.onStepSelected(step, index);

    var section = document.querySelector(`#group${option_group.id}`);

    if (section) {
      setTimeout(() => {
        section.scrollIntoView();
      }, 500);
    }
  };

  langify = (key) => {
    return `${key}${this.page.suffix}`;
  };

  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  @computed get broker() {
    const user_str = localStorage.getItem("configurator:user");

    if (user_str) {
      return JSON.parse(user_str);
    }

    return null;
  }

  prev_image_url = "";

  @action getImageUrl(type) {
    return (
      appConfig.locale.api +
      `/models/${this.data.slug}/image/${type}.jpg?q=` +
      this.summary.selections.join(",")
    );
  }

  @action getPictureMode(mode) {
    return (
      appConfig.locale.api +
      `/models/${this.data.slug}/image/${mode}.jpg?q=` +
      this.summary.selections.join(",")
    );
  }

  @computed get getPicture() {
    return (
      appConfig.locale.api +
      `/models/${this.data.slug}/image/${this.image_mode}.jpg?q=` +
      this.summary.selections.join(",")
    );
  }

  @computed
  get summary() {
    const data = toJS(this.data);

    const result = {
      order_number: this.page.order_number,
      language: this.page.lang,
      locale: appConfig.locale,
      location: this.location,
      url: window.location.href,
      model: {
        id: data.id,
        name: data.name,
        title: data.name,
        picture: data.cover,
        standards: data.standards,
        standards_right: data.standards_right,
        due_today_price: data.due_today_price,
        flat_name: data.flat_name,
        prefix: data.prefix,
        transportation: data.transportation,
        slug: data.slug,
      },
      pdf_path: this.page.pdf_path,
      purchase_price: 0,
      due_today_price: this.data.due_today_price,
      refundable: false,
      type: null, // pdf | purchase
      brokerage: this.user !== undefined,
      form: {
        upgradeOptions: [],
        paymentForm: toJS(this.paymentForm),
        detailsForm: toJS(this.detailsForm),
        brokerageForm: toJS(this.brokerageForm),
      },
    };

    if (data.steps.length > 0) {
      result.upgradeOptions = data.steps
        .sort((x, y) => x.sorting - y.sorting)
        .flatMap((x) =>
          x.option_groups
            .sort((x, y) => y.sorting - x.sorting)
            .flatMap((og) => {
              return og.options
                .sort((x, y) => y.sorting - x.sorting)
                .filter((o) => o.selected)
                .map((oo) => ({
                  ...oo,
                  step: x[this.langify("title")],
                  option_group_x: og[this.langify("title")],
                  option_group_title: og.group_title,
                  show_option_group_title: og.show_group_title,
                  title: oo[this.langify("title")],
                }));
            })
        );

      var steps = this.groupBy(result.upgradeOptions, "step");

      for (var key in steps) {
        var step = steps[key];
        steps[key] = this.groupBy(step, "option_group_title");

        for (var key2 in steps[key]) {
          var option_groups = steps[key][key2];
          steps[key][key2] = this.groupBy(option_groups, "option_group_x");
        }
      }

      result.upgradeOptionsDisplay = steps;

      if (result.upgradeOptions.length > 0) {
        result.purchase_price = result.upgradeOptions.reduce((a, b) => {
          return (
            a +
            (b.price !== null
              ? b.price[this.location.currency] > 0
                ? b.price[this.location.currency]
                : 0
              : 0)
          );
        }, 0);
      }
    }

    var selections = toJS(
      result.upgradeOptions.map((x) =>
        x.additional ? x.id + ":" + x.title : x.id
      )
    );

    result.selections = selections;

    result.model.selected_picture =
      appConfig.locale.api +
      `/models/${this.data.slug}/image/exterior1.jpg?q=` +
      selections.join(",");

    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?q=" +
      btoa(selections.join()).replaceAll("=", "-");
    window.history.pushState({ path: newurl }, "", newurl);

    return result;
  }
}
