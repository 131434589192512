import React from "react";

export const SearchForm = (props) => {
  const [text, setText] = React.useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onFilterBuilds(text);
      }}
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="form-item" style={{ flex: 1 }}>
        <input
          placeholder="FILTER"
          type="text"
          name="search"
          style={{
            padding: 10,
            width: "100%",
            boxSizing: "border-box",
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="form-item">
        <button
          type="submit"
          className="button__clean"
          style={{
            width: "100%",
            textAlign: "center",
            padding: "10px",
          }}
        >
          SEARCH
        </button>
      </div>
    </form>
  );
};
