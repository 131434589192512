import React from "react";
import { inject, observer } from "mobx-react";
import { convertCurrencySummaryLocation } from "../helpers";
import { Translation } from "../Translation";
import { FiEdit2 } from "react-icons/fi";

@inject("AppStore")
@observer
class SummaryStep extends React.Component {
  store = this.props.AppStore;

  render() {
    return (
      <div className="step-summary-content">
        <div className="left">
          <div className="heading">
            <h1>
              <Translation
                lang={this.store.page.lang}
                t="summary_title"
                params={{ model: this.store.data.name }}
              />
            </h1>
          </div>

          <div
            className="image"
            style={{
              backgroundImage:
                this.store.summary.model.selected_picture &&
                `url(${this.store.summary.model.selected_picture})`,
            }}
          ></div>
        </div>
        <div className="options-wrapper">
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: this.store.data.name }}
          ></div>

          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div
                className="selectedModel__subtitle--top uc"
                style={{ borderColor: `${this.store.data.color}` }}
              >
                <Translation t="ORDER SUMMARY" lang={this.store.page.lang} />
              </div>
            </div>
            <div className="upgrades">
              {Object.keys(this.store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        this.store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title !== "null" && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            this.store.summary.upgradeOptionsDisplay[step][
                              option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div
                                className="upgradeoption-option-group"
                                style={{
                                  marginLeft:
                                    option_group_title !== "null" ? 45 : 35,
                                }}
                              >
                                {option_group}
                              </div>

                              {this.store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title !== "null" ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div
                                      className="upgradeoption-option"
                                      dangerouslySetInnerHTML={{
                                        __html: option.title,
                                      }}
                                    ></div>
                                  </span>
                                  <span
                                    className="upgradeoption-edit"
                                    style={{
                                      cursor: "pointer",
                                      color: this.store.data.color,
                                      marginRight: 10,
                                      fontWeight: "bold",
                                      padding: 5,
                                    }}
                                    onClick={() => {
                                      this.store.goToOption(option);
                                    }}
                                  >
                                    <FiEdit2 />
                                  </span>

                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: this.store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      this.store.location
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation
                  lang={this.store.page.lang}
                  t="footer_purchase_price"
                />
              </div>
              <div className="">
                {convertCurrencySummaryLocation(
                  this.store.summary.purchase_price,
                  this.store.location
                )}
              </div>
            </div>

            {this.store.data.transportation && (
              <div
                className="purchase-price transportation"
                style={{ marginTop: 15 }}
              >
                <div style={{ fontSize: 14 }}>
                  <Translation
                    t="Transportation Fee"
                    lang={this.store.page.lang}
                  />{" "}
                  *
                </div>
                <div className="">
                  {convertCurrencySummaryLocation(
                    this.store.data.transportation,
                    this.store.location
                  )}
                </div>
              </div>
            )}

            <div className="purchase-price total" style={{ marginTop: 15 }}>
              <div>
                <strong>
                  <Translation t="Total Price" lang={this.store.page.lang} />
                </strong>
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  this.store.summary.purchase_price +
                    (this.store.data.transportation
                      ? this.store.data.transportation.eur
                      : 0),
                  this.store.location
                )}
              </div>
            </div>

            <div style={{ marginTop: 25, fontSize: 12 }}>
              <Translation
                t="TRANSPORTATION_INFO"
                lang={this.store.page.lang}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryStep;
