import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { dateFormat } from "../helpers";
import { Loading } from "../Components/Loading";
import { SearchForm } from "../Components/SearchForm";
import "../App.css";

@inject("AppStore")
@observer
class ServiceList extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { location } = this.props.match.params;

    this.store.getLocation(location);
    this.store.loadConfig();

    const { user } = this.store;
    if (!user) window.location.href = "/";

    this.store.getServiceList();
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.page.lang}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {this.store.configuration && this.store.configuration.logo && (
                <img
                  alt="logo"
                  src={appConfig.locale.api + this.store.configuration.logo.url}
                  className="logo"
                />
              )}
              <span>
                {this.store.location && this.store.location.displayname}
              </span>
            </a>
          </div>
          {this.store.user ? (
            <div className="header-user">
              <span>{this.store.user.user.fullname} -&nbsp;</span>
              <a onClick={this.store.logout}>LOGOUT</a>
            </div>
          ) : (
            <div className="header-user">
              <a href={`/account`}>Account</a>
            </div>
          )}
        </div>
        <div className={`content`} style={{ flexDirection: "column" }}>
          <div className="modelSelector__filter">
            <div style={{ padding: 20, background: "#fff" }}>
              <SearchForm
                onFilterBuilds={(text) => {
                  this.store.onFilterBuilds(text);
                }}
              />
            </div>
          </div>

          <div
            className="modelSelector__left"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ padding: 50, paddingTop: 0 }}>
              {this.store.selectedStatus}
              <table className="order-table">
                <thead>
                  <tr>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) =>
                          this.store.onSortBuilds(e, "order_number")
                        }
                      >
                        Order#
                        {this.store.builds_sort === "order_number" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) => this.store.onSortBuilds(e, "date")}
                      >
                        Date
                        {this.store.builds_sort === "date" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) => this.store.onSortBuilds(e, "firstname")}
                      >
                        Customer
                        {this.store.builds_sort === "firstname" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) =>
                          this.store.onSortBuilds(e, "model_slug")
                        }
                      >
                        Model
                        {this.store.builds_sort === "model_slug" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.store.filtered_builds.map(
                    (b, i) =>
                      b.model_slug &&
                      (this.store.selectedStatus === "" ||
                        this.store.selectedStatus === b.status) && (
                        <tr key={i} data-boat={JSON.stringify(b)}>
                          <td>{b.order_number}</td>
                          <td>{dateFormat(b.date)}</td>
                          <td>
                            {b.firstname} {b.lastname}
                          </td>
                          <td>{b.model_slug}</td>

                          <td>
                            <a
                              style={{ padding: 5 }}
                              href={`/en/service/${b.order_number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Servis Kontrol
                            </a>

                            <a
                              style={{ padding: 5 }}
                              href={`/en/configuration/${b.order_number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Configurator Kontrol
                            </a>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceList;
