import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

export default class LightboxImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: props.photoIndex,
      images: props.images,
    };
  }

  render() {
    const { photoIndex } = this.state;

    return (
      <div>
        <Lightbox
          mainSrc={this.state.images[photoIndex]}
          nextSrc={
            this.state.images[(photoIndex + 1) % this.state.images.length]
          }
          prevSrc={
            this.state.images[
              (photoIndex + this.state.images.length - 1) %
                this.state.images.length
            ]
          }
          onCloseRequest={() => this.props.onCloseRequest()}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex:
                (photoIndex + this.state.images.length - 1) %
                this.state.images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % this.state.images.length,
            })
          }
        />
      </div>
    );
  }
}
