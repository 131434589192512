import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "mobx-react";
import { Store } from "./store";
import App from "./Screens/App";
import Select from "./Screens/Select";
import Broker from "./Screens/Broker";
import Builds from "./Screens/Builds";
import ServiceList from "./Screens/ServiceList";
import ServiceDetail from "./Screens/ServiceDetail";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "animate.css";
import ConfigurationCheck from "./Screens/ConfigurationCheck";

const appStore = new Store();

ReactDOM.render(
  <Provider AppStore={appStore}>
    <Router>
      <Route path="/:lang/build/:slug/:number" exact component={App} />
      <Route path="/:lang/build/:slug" exact component={App} />
      <Route path="/:lang/builds" exact component={Builds} />
      <Route path="/:lang/service" exact component={ServiceList} />
      <Route path="/:lang/service/:id" exact component={ServiceDetail} />
      <Route
        path="/:lang/configuration/:id"
        exact
        component={ConfigurationCheck}
      />
      <Route path="/:lang/account" exact component={Broker} />
      <Route path="/:lang" exact component={Select} />
      <Route path="/" exact component={Select} />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
